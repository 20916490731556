<template>
  <div class="pricing sec" id="pricing">
    <h3>Take a look at our<br />standard rates.</h3>
    <a @click="goToNewRoute()">
      <div class="btn blue light max fl">
        <p>Read more</p>
        <div class="bg">
          <p>Read more</p>
        </div>
      </div>
    </a>

    <div class="content">
      <div class="prices">
        <div class="price bl">
          <p class="bold">Branding & Graphic design</p>
          <img src="../assets/Graphic design.jpg" alt="">
          <div class="tag">
            <p class="small">From</p>
            <h4>R 300</h4>
          </div>
        </div>
        <div class="price blck">
          <p class="bold">Software & Web development</p>
          <img src="../assets/Web development.jpg" alt="">
          <div class="tag">
            <p class="small">From</p>
            <h4>R 2 300</h4>
          </div>
        </div>
        <div class="price bl">
          <p class="bold">Ads & Motion graphics</p>
          <img src="../assets/Motion graphics.jpg" alt="">
          <div class="tag">
            <p class="small">From</p>
            <h4>R 500</h4>
          </div>
        </div>
      </div>
    </div>
    <a @click="goToNewRoute()">
      <div class="btn blue light max smfl">
        <p>Read more</p>
        <div class="bg">
          <p>Read more</p>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  methods: {
    async goToNewRoute() {
      await this.$router.push("/packages");
      window.location.reload();
    },
  },
};
</script>
<style>
.pricing {
  position: relative;
}
.pricing img {
  width: 100%;
}
.pricing .fl {
  position: absolute;
  top: 14rem;
  right: 10rem;
}
.prices {
  display: flex;
  gap: 0.1rem;
  align-items: center;
  justify-content: center;
}
.bl {
  background-color: var(--blue);
  width: 16rem;
  height: 19rem;
  border-radius: 0.8rem;
}
.blck {
  background-color: black;
  width: 18rem;
  height: 23rem;
  border-radius: 1rem;
}
.price p {
  color: white;
  text-align: center;
}
.price {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.price h4 {
  color: white;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
@media screen and (min-width: 521px) {
  .smfl {
    display: none;
  }
}
@media screen and (max-width: 520px) {
  #app .pricing {
    min-height: fit-content;
  }
  .bl {
    height: 9rem;
    width: 7rem;
    border-radius: 0.4rem;
  }
  .fl {
    display: none;
  }
  .blck {
    height: 10rem;
    width: 7.5rem;
    border-radius: 0.5rem;
  }
  .price {
    padding: 0.3rem 0.1rem;
  }
  .price img {
    width: 80%;
  }
  .pricing a {
    position: relative;
  }
  .price p {
    font-size: 0.7rem;
  }
  .price h4 {
    font-size: 1.2rem;
    line-height: 1.2rem;
  } 
  .content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
  .smfl {
    margin-top: 2rem;
  }
}
</style>
