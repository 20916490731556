<template>
  <div class="about sec big-screen" id="about">
    <h3>How it all started.</h3>
    <div class="content">
      <div class="abt">
        <div class="info">
          <p class="small">About us</p>
          <h6>Style & aesthetics meet functionality</h6>
          <p>
            Bekause Marketing offers a fresh approach, seamlessly blending style
            and functionality. Based in Cape Town, we specialize in designing
            visually striking graphics, building innovative websites, and
            creating captivating motion visuals. Our streamlined development
            process ensures top-quality solutions that empower businesses to
            stand out and engage their audiences effectively.
          </p>
        </div>
        <img src="../assets/About.png" alt="" />
      </div>
      <div class="more">
        <div class="info">
          <p class="small">Our vision</p>
          <h6>Empowering start-ups and Corporates</h6>
          <p>
            To be the leading digital agency that transforms the development
            process, redefining how brands connect with their audiences by
            blending creativity and technology, shaping the future of digital
            marketing, and becoming the go-to choice for startups.
          </p>
        </div>
        <div class="info">
          <p class="small">Our mission</p>
          <h6>Drive businesses growth</h6>
          <p>
            Develop affordable, high-quality marketing solutions, simplify the
            development process, and make these services easily accessible to
            startups
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="about sec small-screen" id="about_">
    <h3>How it all started</h3>
    <div class="content">
      <img src="../assets/About.png" alt="" />
      <div class="info">
        <p class="small">About us</p>
        <h6>Style & aesthetics meet functionality</h6>
        <p>
          Bekause Marketing offers a fresh approach, seamlessly blending style
          and functionality. Based in Cape Town, we specialize in designing
          visually striking graphics, building innovative websites, and creating
          captivating motion visuals. Our streamlined development process
          ensures top-quality solutions that empower businesses to stand out and
          engage their audiences effectively.
        </p>
      </div>
      <div class="info">
        <p class="small">Our vision</p>
        <h6>Empowering start-ups and Corporates</h6>
        <p>
          To be the leading digital agency that transforms the development
          process, redefining how brands connect with their audiences by
          blending creativity and technology, shaping the future of digital
          marketing, and becoming the go-to choice for startups.
        </p>
      </div>
      <div class="info">
        <p class="small">Our mission</p>
        <h6>Drive businesses growth</h6>
        <p>
          Develop affordable, high-quality marketing solutions, simplify the
          development process, and make these services easily accessible to
          startups
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
#app .about .content {
  flex-direction: column;
}
.abt,
.more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 6rem;
}
.abt img {
  height: 18rem;
}
.more {
  align-items: flex-start;
}
.info {
  width: 26rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.info .small {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--blue);
}
.info h6 {
  font-weight: 600;
  font-size: 1.6rem;
}
.info p {
  font-size: 0.9rem;
  font-weight: 400;
}
#app .small-screen .info {
  width: 100%;
}
#app .small-screen .content {
  gap: 2rem;
}
.small-screen img {
  width: 100%;
}
</style>
