<template>
  <div class="reviews sec" id="reviews" v-if="1 == 2">
    <h3>
      Or better yet <br />
      take our clients word.
    </h3>
    <div class="content">
      <div class="revs">
        <div class="rev">
          <p class="msg">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
            consequatur aliquam sapiente maxime accusantium culpa, sint quidem
            minus voluptatem? Repellendus nostrum eligendi odit veniam maxime
            laudantium, dolores maiores rerum consequatur.
          </p>
          <p class="bold">James Murry</p>
        </div>
        <div class="rev">
          <p class="msg">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
            consequatur aliquam sapiente maxime accusantium culpa, sint quidem
            minus voluptatem? Repellendus nostrum eligendi odit veniam maxime
            laudantium, dolores maiores rerum consequatur.
          </p>
          <p class="bold">James Murry</p>
        </div>
        <div class="rev">
          <p class="msg">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum
            consequatur aliquam sapiente maxime accusantium culpa, sint quidem
            minus voluptatem? Repellendus nostrum eligendi odit veniam maxime
            laudantium, dolores maiores rerum consequatur.
          </p>
          <p class="bold">James Murry</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.reviews {
  background-color: black;
  color: white;
}
.reviews p {
  color: white;
}
.revs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.revs .rev {
  border: 0.1rem solid white;
  width: 16rem;
  height: 20rem;
}
.rev .msg {
  font-size: 0.8rem;
  text-align: center;
  padding: 1rem;
}
.rev .bold {
  text-align: center;
}
</style>
