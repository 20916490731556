<template>
  <div class="track win sec" v-if="project">
    <div class="project">
      <div class="p_left">
        <div class="headers">
          <h4>Dashboard</h4>
          <router-link to="">
            <div class="btn dark black">
              <p>Back</p>
              <div class="bg"><p>Back</p></div>
            </div>
          </router-link>
        </div>
        <div class="btns">
          <p @click.prevent="navTo(1)">
            <span class="open" v-if="tab == 1">Overview</span
            ><span class="closed" v-if="tab != 1">Overview</span>
          </p>
          <p @click.prevent="navTo(2)">
            <span class="open" v-if="tab == 2">Milestones</span
            ><span class="closed" v-if="tab != 2">Milestones</span>
          </p>
          <p @click.prevent="navTo(3)">
            <span class="open" v-if="tab == 3">Support</span
            ><span class="closed" v-if="tab != 3">Support</span>
          </p>
          <p @click.prevent="navTo(4)" class="far">
            <span class="open" v-if="tab == 4">Report issue</span
            ><span class="closed" v-if="tab != 4">Report issue</span>
          </p>
        </div>
        <div class="overview" v-if="tab == 1">
          <div class="headers hd">
            <div class="h_left">
              <h5>{{ project?.proj_name }}</h5>
              <p class="sml">{{ project?.proj_type }}</p>
            </div>
            <div class="h_right">
              <p>ETA: 2 weeks</p>
            </div>
          </div>
          <div class="proj_desc lbl">
            <p>Description</p>
            <div class="div"></div>
          </div>
          <div class="r_desc">
            <p>{{ project?.proj_desc }}</p>
          </div>
          <div class="proj_status lbl">
            <p>Status</p>
            <div class="div"></div>
          </div>
          <div class="r_status">
            <p>
              <span>{{ wStages[project?.dev_stage]?.stage }}</span> <br />{{
                wStages[project?.dev_stage]?.description
              }}
            </p>
          </div>
          <div class="total_cost">
            <p class="sml">Total amount</p>
            <h5>R {{ project?.total_cost.toLocaleString() }}</h5>
          </div>
        </div>
        <div class="milestones" v-if="tab == 2">
          <div class="m_stone">
            <div class="m_list" v-for="(stage, index) in wStages" key="stage">
              <div
                class="m_item complete"
                v-if="index < project?.dev_stage"
              >
                <p>{{ stage?.stage }}</p>
                <p class="m_desc"><div class="dot"></div>{{ stage?.short }}</p>
                <p class="m_status">Complete</p>
              </div>
            </div>
            <div class="m_list" v-for="(stage, index) in wStages" key="stage">
              <div
                class="m_item in_progress"
                v-if="index == project?.dev_stage"
              >
                <p>{{ stage?.stage }}</p>
                <p class="m_desc"><div class="dot"></div>{{ stage?.short }}</p>

                <p class="m_status">In progress</p>
              </div>
            </div>
            <div class="m_list" v-for="(stage, index) in wStages" key="stage">
              <div class="m_item upcoming" v-if="index > project?.dev_stage">
                <p>{{ stage?.stage }}</p>
                <p class="m_desc"><div class="dot"></div>{{ stage?.short }}</p>
                <p class="m_status">Upcoming</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p_right">
        <h4>Invoices</h4>
        <div class="invoices"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tab: 1,
    };
  },
  computed: {
    ...mapGetters(["project", "wStages"]),
  },
  methods: {
    navTo(x) {
      this.tab = x;
    },
  },
};
</script>
<style scoped>
.project {
  border-radius: 1rem;
  background-color: rgb(244, 244, 244);
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.1);
  height: 38rem;
  width: 56rem;
  display: flex;
  align-items: center;
}
.project > div {
  height: 36rem;
  max-height: 36rem;
  padding: 1.6rem 2.2rem;
}
.project .headers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.6rem;
}
.project .hd {
  margin-bottom: 0;
}
.project .lbl {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-top: 0.4rem;
}
.project .lbl p {
  font-size: 0.7rem;
  font-weight: 500;
  color: var(--blue);
}
.project .lbl div {
  height: 0.1rem;
  width: 100%;
  background-color: rgb(204, 204, 204);
  border-radius: 0.05rem;
}
.project .r_desc p {
  color: black;
  font-size: 0.8rem;
}
.project .r_status p {
  color: black;
  font-size: 0.8rem;
}
.r_status p span {
  margin-bottom: 0.1rem;
  color: black;
}

.project p {
  font-size: 0.7rem;
  font-weight: 500;
}
.overview .sml {
  line-height: 0.7rem;
}
.total_cost {
  padding-top: 1rem;
  border-top: 0.1rem solid rgb(204, 204, 204);
  margin-top: 0.6rem;
}
.overview h5 {
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.p_left {
  height: 100%;
  border-right: 0.1rem solid rgb(186, 186, 186);
  width: 29rem;
}
.overview {
  height: 28rem;
  width: 100%;
  background-color: white;
  border-radius: 0.8rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.track {
  padding: 0;
  background-color: white;
}
.track h4 {
  font-size: 1.2rem;
  font-weight: 700;
}
.btns {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-bottom: 0.6rem;
}
.btns .far {
  margin-left: auto;
}
.btns p {
  font-size: 0.7rem;
  font-weight: 500;
  transition: 0.4s;
}
.btns .open {
  color: black;
}
.btns p:hover {
  cursor: pointer;
  color: black;
  transition: 0.4s;
}
.milestones {
  width: 100%;
  max-height: 28rem;
  height: 28rem;

  overflow-y: scroll;
}
.m_stone {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.m_list {
  width: 100%;
  height: fit-content;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
}
.m_item {
  height: 5rem;
  width: 100%;
  background-color: white;
  border-radius: 0.6rem;
  padding: 0.6rem;
  margin-top: 0.4rem;
  overflow: hidden;
  position: relative;
}
.m_item .m_desc {
  display: flex;
  align-items: center;
}
.m_desc .dot {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
  margin-bottom: 0.1rem;
  border-radius: 0.2rem;
}
.in_progress {
  height: 9rem;
}
.complete .dot {
  background-color: gray;
}
.in_progress .dot {
  background-color: var(--blue);
}
.m_item .m_desc {
  font-size: 0.7rem;
}
.m_item .m_status {
  padding: 0.1rem 0.8rem;
  position: absolute;
  border-radius: 1rem;
  top: 0.6rem;
  right: 0.6rem;
  
}
.complete .m_status {
  background-color: rgb(237, 237, 237);
}
.in_progress .m_status {
  background-color: var(--light-blue);
  color: var(--blue);
}

</style>
