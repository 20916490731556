<template>
  <div class="main">
    <navbar />
    <router-view />
  </div>
</template>
<script>
import navbar from "./components/navbar.vue";
export default {
  components: {
    navbar
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --light-blue: #f4fbfd;
  --light-text: #555555;
  --blue: #28aae1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: #e9e9e9;
  color: black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
}

.main {
  background-color: white;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow: scroll;
  scroll-behavior: smooth;
}
.sec p {
  color: grey;
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2.2rem;
  overflow: hidden;
  border-radius: 0.4rem;
}
.max {
  width: 8.4rem;
  height: 3rem;
  border-radius: 0.6rem;
}
.black {
  border: 0.1rem solid black;
  color: black;
}
.white {
  border: 0.1rem solid white;
  color: white;
}
.blue {
  border: 0.1rem solid var(--blue);
  color: var(--blue);
}
.blue > p {
  color: var(--blue);
}
.black > p {
  color: black;
}
.blue .bg > p {
  color: white;
}
.black .bg > p {
  color: white;
}
.white > p {
  color: white;
}
.white .bg > p {
  color: black;
}
.black .bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2.2rem;
  background-color: black;
  color: white;
}
.blue .bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2.2rem;
  background-color: var(--blue);
  color: white;
}
.white .bg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 2.2rem;
  background-color: white;
  color: black;
}
.btn p {
  font-size: 0.7rem;
  font-weight: 500;
}
.light .bg {
  position: absolute;
  left: -6.1rem;
  transition: 0.4s ease-in-out;
}
.dark .bg {
  position: absolute;
  left: -0.1rem;
  transition: 0.4s ease-in-out;
}
a {
  text-decoration: none;
  width: fit-content;
}
.light:hover .bg {
  left: -0.1rem;
  transition: 0.4s;
}
.dark:hover .bg {
  left: -6.1rem;
  transition: 0.4s;
}
.max .bg {
  width: 8.4rem;
  height: 3rem;
  border-radius: inherit;
}
#app .max p {
  font-size: 0.8rem;
}
.max.light:hover .bg {
  left: -0.1rem;
  transition: 0.4s;
}
.max.dark:hover .bg {
  left: -8.5rem;
  transition: 0.4s;
}
.max.dark .bg {
  left: -0.1rem;
  transition: 0.4s;
}
.max.light .bg {
  left: -8.5rem;
  transition: 0.4s;
}
::-webkit-scrollbar {
  display: none;
}
.idle {
  position: absolute;
  right: 10rem;
  top: 5rem;
}
button {
  border: none;
  background: none;
  width: fit-content;
  font-family: inherit;
}
.btn:hover {
  cursor: pointer;
}
a:hover {
  cursor: pointer;
}
@media screen and (max-width: 520px) {
  #app .sec .idle {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .back {
    display: none;
  }
  .x-mark {
    height: 1.8rem;
  }
}
@media screen and (min-width: 521px) {
  .x-mark {
    display: none;
  }
}
</style>
