import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
  ,
  {
    path: '/track',
    name: 'track',
    component: function () {
      return import('../views/TrackView.vue')
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: function () {
      return import('../views/TermsView.vue')
    }
  }
  ,
  {
    path: '/packages',
    name: 'packages',
    component: function () {
      return import('../views/PricingView.vue')
    }
  }
  ,
  {
    path: '/quote',
    name: 'quote',
    component: function () {
      return import('../views/QuoteView.vue')
    }
  }
  ,
  {
    path: '/portfolio',
    name: 'portfolio',
    component: function () {
      return import('../views/PortfolioView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
