<template>
  <div class="portfolio sec big-screen" id="portfolio">
    <h3>
      Don't just take our word for it <br />take a look for yourself at some of
      our work.
    </h3>
    <div class="content">
      <div class="info">
        <p class="small">Portfolio</p>
        <h6>Go through our portfolio of client projects</h6>
        <p>
          Explore some of our most impressive client projects to date! Each one
          reflects our commitment to innovation, quality, and delivering
          tailored solutions that exceed expectations. We're proud to showcase
          the creativity and dedication that have brought our clients' visions
          to life.
        </p>
        <a href="" @click.prevent="goToNewRoute()">
          <div class="btn light blue max">
            <p>Read more</p>
            <div class="bg">
              <p>Read more</p>
            </div>
          </div>
        </a>
      </div>
      <img src="../assets/Portfolio.png" alt="" />
    </div>
  </div>
  <div class="portfolio sec small-screen" id="portfolio_">
    <h3>
      Don't just take our word for it take a look for yourself at some of our
      work.
    </h3>
    <div class="content">
      <img src="../assets/Portfolio.png" alt="" />
      <div class="info">
        <p class="small">Portfolio</p>
        <h6>Go through our portfolio of client projects</h6>
        <p>
          Explore some of our most impressive client projects to date! Each one
          reflects our commitment to innovation, quality, and delivering
          tailored solutions that exceed expectations. We're proud to showcase
          the creativity and dedication that have brought our clients' visions
          to life.
        </p>
        <a href="" @click.prevent="goToNewRoute()">
          <div class="btn light blue max">
            <p>Read more</p>
            <div class="bg">
              <p>Read more</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    async goToNewRoute() {
      await this.$router.push("/portfolio");
      window.location.reload();
    },
  },
};
</script>
<style>
.info a {
  margin-top: 1rem;
}
.portfolio img {
  height: 20rem;
}
.portfolio .content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}
.small-screen img{
  height: fit-content;
 width: 100%;
 object-fit: contain;
}
</style>
