<template>
  <div class="services sec big-screen" id="services">
    <h3>
      We provide digital and software services<br />to help businesses grow.
    </h3>
    <div class="content">
      <div class="servs">
        <div class="serv">
          <img src="../assets/GraphDe.png" alt="" />
          <p class="bold">Branding & Graphic design</p>
          <p>
            Unlock the full potential of your business with our extraordinary
            graphic design services. From captivating posters to distinctive
            logo designs, we create visually stunning solutions that drive
            unparalleled traffic and success for your brand.
          </p>
        </div>
        <div class="serv">
          <img src="../assets/WebDev.png" alt="" />
          <p class="bold">Software and Web development</p>
          <p>
            Elevate your online presence with a meticulously crafted website
            that seamlessly combines user-friendliness and captivating design,
            ensuring an exceptional browsing experience for your visitors.
          </p>
        </div>
        <div class="serv">
          <img src="../assets/MoGraph.png" alt="" />
          <p class="bold">Ads & Motion graphics</p>
          <p>
            From unique logo reveals to captivating animated ads. Create Motion
            Graphics to effectively promote your company, effortlessly
            captivating viewers and leaving a lasting impression.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="services sec small-screen" id="services_">
    <h3>We provide digital and software services to help businesses grow.</h3>
    <div class="content">
      <div class="servs">
        <div class="serv">
          <img src="../assets/GraphDe.png" alt="" />
          <p class="bold">Branding & Graphic design</p>
          <p>
            Unlock the full potential of your business with our extraordinary
            graphic design services. From captivating posters to distinctive
            logo designs, we create visually stunning solutions that drive
            unparalleled traffic and success for your brand.
          </p>
        </div>
        <div class="serv">
          <img src="../assets/WebDev.png" alt="" />
          <p class="bold">Software and Web development</p>
          <p>
            Elevate your online presence with a meticulously crafted website
            that seamlessly combines user-friendliness and captivating design,
            ensuring an exceptional browsing experience for your visitors.
          </p>
        </div>
        <div class="serv">
          <img src="../assets/MoGraph.png" alt="" />
          <p class="bold">Ads & Motion graphics</p>
          <p>
            From unique logo reveals to captivating animated ads. Create Motion
            Graphics to effectively promote your company, effortlessly
            captivating viewers and leaving a lasting impression.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.services {
  background-color: black;
  color: white;
}
.services h3 {
  margin-bottom: 2rem;
}
.services .servs {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.services .servs .serv {
  width: 18rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
#app .services .content .servs img {
  width: fit-content;
  height: 3rem;
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(206deg)
    brightness(104%) contrast(101%);
}
.services .servs .serv p {
  color: white;
}
.serv .bold {
  font-weight: 500;
  font-size: 1rem;
}
.serv p {
  font-weight: 400;
  font-size: 0.9rem;
}
.small-screen .servs {
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.small-screen .serv {
  width: 100%;
}
</style>
