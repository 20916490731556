import Project from '@/components/project.vue'
import { createStore } from 'vuex'

export default createStore({
  state: {
    projectt: {
      proj_id: "",
      proj_name: "Booking website",
      proj_type: "Web development",
      dev_stage: 3,
      proj_desc: "This is is a booking website to hlp clients of a guest house book rooms",
      total_cost: 2500,
      completion_date: "07-02-2025",
      design_link: "",
      client_name: "John Doe",
      client_email: "john@email.com",
      client_cell: "",

    },
    payments: [
      {
        payment_id: "",
        payment_amount: 300,
        payment_type: "Design fee",
        due_date: "",
        is_paid: 0,
        project_id: "",
      },
      {
        payment_id: "",
        payment_amount: 1250,
        payment_type: "Deposit",
        due_date: "",
        is_paid: 0,
        project_id: "",
      }
    ],
    wStages: [
      {
        stage: "Pre-production",
        description: "The process begins with gathering requirements, defining project goals, and planning the timeline. This phase ensures that our vision aligns with your expectations before any actual work starts. If needed, we'll schedule a meeting to go through these details together, ensuring complete clarity and alignment before moving forward.",
        short: "Gathering information."
      },
      {
        stage: "Initiate design",
        description: "We’re ready to kick off the design process! Please note that the web design process is a separate service, dedicated to bringing your ideas to life in a visual format therefor a design fee is required so we can proceed with brainstorming, sketching, and conceptualizing the overall look and feel of the project. This phase is crucial in ensuring that the final product aligns with your vision.",
        short: "Ready start designing."
      },
      {
        stage: "Designing",
        description: "Detailed design work is underway, including the creation of wireframes, prototypes, and mockups. Feedback is essential during this phase to refine the design until it perfectly aligns with your vision.",
        short: "Design has begun."
      },
      {
        stage: "Design complete",
        description: "The design phase is now finalized, with all visual elements approved and ready for development. The design is both aesthetically pleasing and functionally sound. To approve the design and initiate the development phase, a 50% deposit is required as a gesture of good business.",
        short: "Design complete, ready to start development."
      },
      {
        stage: "Developing",
        description: "Development is in full swing, transforming the design into a fully functional software product. This includes backend and frontend development, as well as integrating all necessary components. The development process will be split into 1-week sprints, with reviews scheduled on weekends to provide regular progress updates and make any necessary adjustments to keep everything on track.",
        short: "Dynamic value"
      },
      {
        stage: "Review",
        description: "We have entered the review stage. Kindly utilize the provided link to thoroughly test both the appearance and functionality of the completed section.",
        short: "Review and test the project."
      },
      {
        stage: "Developing (Continued)",
        description: "The remaining aspects of the project are being built, incorporating changes and improvements based on your feedback.",
        short: "Dynamic value",
      },
      {
        stage: "Finalizing",
        description: "We have conducted extensive testing to ensure everything works as intended. Bugs, performance issues, and usability concerns have been addressed, and the product is now ready for deployment. Please make the final payment to initiate the deployment and migration process.",
        short: "Making sure everything works."
      },
      {
        stage: "Deployment and migration",
        description: "The software is being deployed to the production environment, with any necessary data migrated. Everything is being set up for smooth operation.",
        short: "Deploying your project."
      },
      {
        stage: "Complete",
        description: "The project is now complete. The final product, along with any necessary documentation and support, is handed over, marking the successful conclusion of the development process.",
        short: "All done."
      }
    ],
    mStages: [
      {
        stage: "Pre-production",
        description: "The process begins with gathering requirements, defining project goals, and planning the timeline. This phase ensures that our vision aligns with your expectations before any actual work starts. If needed, we'll schedule a meeting to go through these details together, ensuring complete clarity and alignment before moving forward."
      }, {
        stage: "Concept development",
        description: "We’re ready to start developing the concept! To proceed with brainstorming, storyboarding, and conceptualizing the overall look and feel of the animation, a concept development fee is required. This phase is crucial in defining the visual style and narrative direction."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Designing",
        description: "Detailed design work is underway, including creation off all visual elements. Feedback is essential during this phase to refine the design until it perfectly aligns with your vision."
      },
      {
        stage: "Design complete",
        description: "The design phase is now finalized, with all visual elements approved and ready for animation. The design is both aesthetically pleasing and functionally sound. To approve the design and initiate the animation phase, a 50% deposit is required."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Animation production",
        description: "Animation is in full swing, bringing the designs to life. This includes creating keyframes, motion sequences, and integrating audio elements. The animation process will be split into 1-week sprints, with reviews scheduled on weekends to provide regular progress updates and make any necessary adjustments."
      },
      {
        stage: "Review",
        description: "We have entered the review stage. Kindly utilize the provided link to thoroughly watch and test both the appearance and functionality of the completed animation section."
      },
      {
        stage: "Animation (Continued)",
        description: "The remaining aspects of the animation are being completed, incorporating changes and improvements based on your feedback."
      },
      {
        stage: "Finalizing",
        description: "We have conducted extensive checks to ensure everything works as intended. Issues, performance concerns, correct formats, and the animation is now ready for final delivery. Please make the final payment to proceed with the distribution."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Distribution",
        description: "The animation is being Distributed, along with any necessary documentation and support. Everything is being set up for smooth operation and final use."
      },
      {
        stage: "Complete",
        description: "The project is now complete. The final product, along with any necessary documentation and support, is handed over, marking the successful conclusion of the animation project."
      }

    ],
    gStages: [
      {
        stage: "Pre-production",
        description: "The process begins with gathering requirements, defining project goals, and planning the timeline. This phase ensures that our vision aligns with your expectations before any actual work starts. If needed, we'll schedule a meeting to go through these details together, ensuring complete clarity and alignment before moving forward."
      }, {
        stage: "Concept development",
        description: "We’re ready to start developing the concept! To proceed with brainstorming, and conceptualizing the overall look and feel of the graphic, including creation off all visual elements if required. This phase is crucial in defining the visual style and complete look of the project."
      },
      {
        stage: "Concept complete",
        description: "The concept development phase is now finalized, with all visual elements approved and ready for graphic production. The concept design is both aesthetically pleasing and functionally sound. To approve the concept design and initiate graphic production, a 50% deposit is required."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Graphic production",
        description: "Graphic production is in full swing, bringing the concepts to life. This includes creating all graphic pieces, multiple formats and options. The graphic production process will be split into 1-week sprints, with reviews scheduled on weekends to provide regular progress updates and make any necessary adjustments."
      },
      {
        stage: "Review",
        description: "We have entered the review stage. Kindly utilize the provided link to thoroughly go over the completed graphic pieces"
      },
      {
        stage: "Graphic production (Continued)",
        description: "The remaining aspects of the graphic production are being completed, incorporating changes and improvements based on your feedback."
      },
      {
        stage: "Finalizing",
        description: "We have conducted extensive checks to ensure everything looks as intended. Issues, correct formats and the graphic piece is now ready for final delivery. Please make the final payment to proceed with the distribution."
      },
      {
        stage: "Awaiting payment",
        description: "We noticed that we haven't received your payment yet. Please double-check to ensure the payment was processed successfully. If you’ve already made the payment, kindly let us know so we can resolve any issues promptly. Thank you for your attention to this matter."
      },
      {
        stage: "Distribution",
        description: "The graphic piece is being Distributed, along with any necessary documentation and support. Everything is being set up for smooth operation and final use."
      },
      {
        stage: "Complete",
        description: "The project is now complete. The final product, along with any necessary documentation and support, is handed over, marking the successful conclusion of the graphic project."
      }

    ]

  },
  getters: {
    project(state) {
      return state.project
    },
    payments(state) {
      return state.payments
    },
    wStages(state) {
      return state.wStages
    },
    mStages(state) {
      return state.mStages
    },
    gStages(state) {
      return state.gStages
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
