<template>
  <div class="landing sec big-screen pic1" id="home" v-if="digit == 0">
    <div class="content">
      <div class="left">
        <h2 v-if="isFirstH2Visible">
          Your brand's<br />
          <span>digital growth</span>
          <br />
          starts here.
        </h2>
        <h2 v-if="!isFirstH2Visible">
          We put <br />your business <br />
          in the <span>spotlight</span>
        </h2>
        <p>
          Empowering Start-up and Corporate businesses Through Tailored Web
          Development and Digital Marketing Solutions.
        </p>
        <router-link to="quote">
          <div class="btn light white max">
            <p>Get a quote</p>
            <div class="bg">
              <p>Get a quote</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="right">
        <img src="../assets/Dev.png" alt="" />
      </div>
    </div>
  </div>
  <div class="landing sec big-screen pic2" id="home" v-else-if="digit == 1">
    <div class="content">
      <div class="left">
        <h2 v-if="isFirstH2Visible">
          Your brand's<br />
          <span>digital growth</span>
          <br />
          starts here.
        </h2>
        <h2 v-if="!isFirstH2Visible">
          We put <br />your business <br />
          in the <span>spotlight</span>
        </h2>
        <p>
          Empowering Start-up and Corporate businesses Through Tailored Web
          Development and Digital Marketing Solutions.
        </p>
        <router-link to="quote">
          <div class="btn light white max">
            <p>Get a quote</p>
            <div class="bg">
              <p>Get a quote</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="right">
        <img src="../assets/Dev.png" alt="" />
      </div>
    </div>
  </div>
  <div class="landing sec big-screen pic2" id="home" v-else>
    <div class="content">
      <div class="left">
        <h2 v-if="isFirstH2Visible">
          Your brand's<br />
          <span>digital growth</span>
          <br />
          starts here.
        </h2>
        <h2 v-if="!isFirstH2Visible">
          We put <br />your business <br />
          in the <span>spotlight</span>
        </h2>
        <p>
          Empowering Start-up and Corporate businesses Through Tailored Web
          Development and Digital Marketing Solutions.
        </p>
        <router-link to="quote">
          <div class="btn light white max">
            <p>Get a quote</p>
            <div class="bg">
              <p>Get a quote</p>
            </div>
          </div>
        </router-link>
      </div>
      <div class="right">
        <img src="../assets/Dev.png" alt="" />
      </div>
    </div>
  </div>
  <div class="landing sec small-screen" id="home_">
    <div class="content">
      <div class="slo">
        <h2 v-if="isFirstH2Visible">
          Your brand's<br />
          <span>digital growth</span>
          <br />
          starts here.
        </h2>
        <h2 v-if="!isFirstH2Visible">
          We put <br />your business <br />
          in the <span>spotlight</span>
        </h2>
      </div>
      <p>
        Empowering Start-up and Corporate businesses Through Tailored Web
        Development and Digital Marketing Solutions.
      </p>
      <div class="buttons">
        <router-link to="quote">
          <div class="btn light white max">
            <p>Get a quote</p>
            <div class="bg">
              <p>Get a quote</p>
            </div>
          </div>
        </router-link>
        <router-link to="track">
          <div class="btn dark blue max">
            <p>Track</p>
            <div class="bg">
              <p>Track</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFirstH2Visible: true, // Control visibility
      digit: 0,
    };
  },
  methods: {
    getDigit() {
      this.digit = Math.round(Math.random(2));
    },
  },
  mounted() {
    this.getDigit(),
      setInterval(() => {
        this.isFirstH2Visible = !this.isFirstH2Visible;
      }, 10000); // Toggle every 10 seconds
  },
};
</script>
<style>
.sec {
  width: 100vw;
  min-height: 100vh;
  padding: 10rem 10rem 4rem 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .landing.big-screen {
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 12rem 10rem 4rem 10rem;
  color: white;
}
.pic1 {
  background-image: url(../assets/website\ wallpaper.png);
}
.pic2 {
  background-image: url(../assets/Wallpaper.png);
}
.landing .content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 3rem;
}
.landing.big-screen h2 {
  font-size: 4rem;
  line-height: 4.6rem;
  font-weight: 600;
}
.landing.big-screen h2 span {
  color: var(--blue);
  font-size: 4.4rem;
  line-height: 5rem;
}
.landing p {
  color: white;
}
.landing .left {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 55%;
}
.landing .left a {
  margin-top: 0.8rem;
}
.right {
  position: relative;
  width: 45%;
  height: 32rem;
}
.landing .right img {
  width: 32rem;
  position: absolute;
  top: 0;
  display: none;
}
#app .landing.small-screen {
  background-image: url(../assets/Phone\ wallpaper.png);
  background-size: cover;
  background-repeat: no-repeat;
}
#app .small-screen .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#app .small-screen.landing .content p {
  color: white;
  text-align: center;
}
#app .small-screen.landing {
  background-color: var(--blue);
  color: white;
}

.landing.small-screen h2 {
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: 700;
}
.landing.small-screen h2 span {
  color: var(--blue);
  font-size: 2.4rem;
  line-height: 3.2rem;
}
.small-screen .content .slo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.small-screen .content .hl {
  font-size: 2.4rem;
  padding: 0.4rem 0.6rem;
  background-color: black;
  color: white;
}
@media screen and (max-width: 521px) {
  #app .landing.big-screen {
    display: none;
  }
}
</style>
