<template>
  <div class="footer big-screen">
    <div class="end">
      <div class="left">
        <h5 class="wht">Your brand's</h5>
        <h5 class="blck_bg">digital growth</h5>
        <h5 class="wht">starts here</h5>
      </div>
      <div class="right_end">
        <div class="buttons">
          <router-link to="quote">
            <div class="btn dark black max">
              <p>Get a quote</p>
              <div class="bg">
                <p>Get a quote</p>
              </div>
            </div>
          </router-link>
          <router-link to="track">
            <div class="btn light white max">
              <p>Track</p>
              <div class="bg">
                <p>Track</p>
              </div>
            </div>
          </router-link>
        </div>
        <p>Do everything in one place.</p>
      </div>
    </div>
    <div class="ft">
      <div class="ft_left">
        <img src="../assets/Marketing Logo Black.svg" alt="" />
        <p>
          Empowering Start-ups and Corporates <br />
          in the Digital Age!
        </p>
        <p>
          bekause39@gmail.com <br />
          (082) 794 8253
        </p>
        <p>
          Skillfully crafted by <b>Bekause Marketing</b> <br />
          &copy;2025 Bekause (Pty)Ltd | All right reserved
        </p>
      </div>
      <div class="ft_right">
        <div class="ft_services">
          <p class="small">Solutions</p>
          <p>Graphic design</p>
          <p>Web development</p>
          <p>Motion graphics</p>
        </div>
        <div class="patners">
          <p class="small">Partners</p>
          <p>Bekause Delicacies</p>
          <p>Bekause Farming</p>
          <p>Bekause Cleaning</p>
        </div>
        <div class="comp">
          <p class="small">Compliance</p>
          <p class="Ts" @click.prevent="goToNewRoute()">Terms of use</p>
        </div>
      </div>
      <div class="pay">
        <p class="small">Powered by</p>
        <img src="../assets/paystack-logo-white.png" alt="" />
      </div>
    </div>
  </div>
  <div class="footer small-screen">
    <div class="content">
      <img src="../assets/Marketing Logo Black.svg" alt="" />
      <p>
        Empowering Start-ups and Corporates <br />
        in the Digital Age!
      </p>
      <p>
        bekause39@gmail.com <br />
        (082) 794 8253
      </p>
      <p>
        Skillfully crafted by <b>Bekause Marketing</b> <br />
        &copy;2025 Bekause(Pty)Ltd | All right reserved
      </p>
      <p @click.prevent="goToNewRoute()">Terms of use</p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    async goToNewRoute() {
      await this.$router.push("/terms");
      window.location.reload();
    },
  },
};
</script>
<style>
#app footer {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 100vh;
  max-height: 100vh;
}
.end {
  background-color: var(--blue);
  width: 100vw;
  height: 40vh;
  padding: 2rem 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ft {
  background-color: black;
  width: 100vw;
  height: 60vh;
  color: white;
  padding: 4rem 10rem;
  display: flex;
}
.ft p {
  font-size: 0.9rem;
}
.ft .ft_left {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  border-right: 0.1rem solid white;
  width: 26rem;
  height: 100%;
}
.ft .ft_left > img {
  height: 1.8rem;
  width: fit-content;
}

.ft_right {
  padding: 0 6rem;
  display: flex;
  justify-content: space-between;
  width: 42rem;
}
.ft_right .small {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--blue);
  margin-bottom: 0.8rem;
}
.ft_right a {
  color: white;
}
.ft > div {
  padding-top: 2rem;
}
.end > div {
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.end .left {
  border-right: 0.1rem solid white;
  height: 100%;
  justify-content: center;
}
.end .left .wht {
  font-size: 2.4rem;
  font-weight: 600;
  color: white;
}
.end .left .blck_bg {
  font-size: 2.6rem;
  color: white;
  background-color: black;
  padding: 0.2rem 1rem;
  font-weight: 600;
}
.end .right_end .buttons {
  margin-bottom: 1rem;
}
.end .right_end > p {
  color: white;
  font-weight: 500;
}
.pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Ts:hover {
  color: var(--blue);
  cursor: pointer;
}
.pay img {
  height: 1.8rem;
}
.pay .small {
  text-align: left;
  width: 100%;
}
.small-screen.footer {
  background-color: black;
  color: white;
  padding: 3rem 1rem;
}
.small-screen.footer .content img {
  height: 1.2rem;
  width: fit-content;
}
#app .small-screen.footer .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
  gap: 1rem;
  text-align: left;
  align-items: flex-start;
}
.small-screen.footer p {
  font-size: 0.7rem;
  text-align: left;
}
.small-screen.footer a {
  color: var(--blue);
}
</style>
