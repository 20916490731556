<template>
  <div class="navbar small-screen" v-if="!showLinks">
    <div class="nav">
      <a href="#home_">
        <img src="../assets/Marketing Logo.svg" alt="" class="small-logo" />
      </a>
      <button @click.prevent="toggleShow()">
        <img src="../assets/menu.png" alt="" />
      </button>
    </div>
  </div>
  <div class="navbar small-screen lnks" v-if="showLinks">
    <div class="nav">
      <button @click.prevent="toggleShow()">
        <img src="../assets/x-mark.png" alt="" />
      </button>
      <div class="links">
        <a href="#about_">About Us</a>
        <a href="#services_">Services</a>
        <a href="#why_">Why Us?</a>
        <a href="#portfolio_">Portfolio</a>
        <a href="#reviews_" v-if="1 == 2">Reviews</a>
        <a href="#pricing">Pricing</a>
      </div>
    </div>
  </div>
  <div class="navbar big-screen">
    <div class="nav">
      <a href="#home">
        <img src="../assets/Marketing Logo.svg" alt="" class="big-screen" />
      </a>
      <img
        src="../assets/Marketing logo blue.png"
        alt=""
        class="small-screen"
      />
      <div class="links">
        <a href="#about">About Us</a>
        <a href="#services">Services</a>
        <a href="#why">Why Us?</a>
        <a href="#portfolio">Portfolio</a>
        <a href="#reviews" v-if="1 == 2">Reviews</a>
        <a href="#pricing">Pricing</a>
      </div>
      <div class="buttons">
        <router-link to="track">
          <div class="btn black light">
            <p>Track</p>
            <div class="bg">
              <p>Track</p>
            </div>
          </div>
        </router-link>
        <router-link to="quote">
          <div class="btn blue dark">
            <p>Quote</p>
            <div class="bg">
              <p>Quote</p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLinks: false,
    };
  },
  methods: {
    toggleShow() {
      this.showLinks = !this.showLinks;
    },
  },
};
</script>
<style>
.navbar {
  position: absolute;
  top: 2rem;
  left: 0;
  z-index: +2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: fit-content;
  padding: 0 10rem;
  justify-content: space-between;
}

.navbar.small-screen {
  top: 0.4rem;
}
.navbar.small-screen .nav {
  height: 4rem;
  width: 100%;
  border-radius: 0.8rem;
  padding: 0 0.8rem;
  align-items: center;
}

.navbar.small-screen .small-logo {
  height: 1.8rem;
  margin-top: 0.1rem;
}
.navbar.small-screen .nav button {
  padding: 0;
}
.navbar.small-screen .nav button img {
  height: 2rem;
}

.navbar.lnks .nav {
  height: 20rem;
}
#app .navbar.lnks .nav {
  align-items: center;
  justify-content: center;
  position: relative;
}
#app .navbar.lnks .nav .links {
  color: gray;
  display: flex;
  flex-direction: column;
}
#app .navbar.lnks .nav button {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}

.navbar .nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 5rem;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.1);
  padding: 0 2rem;
}
.navbar .nav .links {
  display: flex;
  align-items: center;
  gap: 1.8rem;
}
.navbar .nav .links a {
  font-size: 0.9rem;
  font-weight: 500;
  transition: 0.4s;
  color: gray;
  text-decoration: none;
}
.buttons {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.navbar img {
  height: 1.8rem;
  margin-top: 0.1rem;
}
.nav > a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .nav .links a:hover {
  color: black;
  transition: 0.4s;
}
@media screen and (max-width: 520px) {
  .navbar {
    padding: 0.6rem 0.5rem;
  }

  #app .navbar .buttons {
    display: none;
  }
}
@keyframes grow {
  0% {
    height: 4rem;
  }
  100% {
    height: 20rem;
  }
}
</style>
