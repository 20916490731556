<template>
  <div class="page">
    <landing />
    <about />
    <services />
    <why />
    <portfolio />
    <reviews />
    <pricing />
    <foot />
  </div>
  
</template>

<script>
// @ is an alias to /src
import navbar from "@/components/navbar.vue";
import landing from "@/components/landing.vue";
import about from "@/components/about.vue";
import services from "@/components/services.vue";
import why from "@/components/why.vue";
import portfolio from "@/components/portfolio.vue";
import reviews from "@/components/reviews.vue";
import pricing from "@/components/pricing.vue";
import foot from "@/components/foot.vue";

export default {
  name: "HomeView",
  components: {
    navbar,
    landing,
    about,
    services,
    why,
    portfolio,
    reviews,
    pricing,
    foot,
  },
};
</script>
<style>
.page {
  position: relative;
  width: 100vw;
  height: fit-content;
  scroll-behavior: smooth;
}
.sec {
  width: 100vw;
  min-height: 100vh;
  padding: 4rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}
.page .sec {
  padding: 12rem 10rem 4rem 10rem;;
}
.sec .content {
  width: 100%;
  padding: 2rem 6rem;
}

.landing .content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.sec h3 {
  font-size: 2.8rem;
  font-weight: 600;
  width: 100%;
}
@media screen and (min-width: 521px) {
  .small-screen {
    display: none;
  }
}
@media screen and (max-width: 520px) {
  .page .sec {
    padding: 3rem 1rem;
  }
  .sec {
    padding: 3rem 1rem;
  }
  .sec .content {
    padding: 1rem;
  }
  .sec h3 {
    font-size: 2rem;
    font-weight: 600;
    width: 100%;
  }
  .sec h6 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  .big-screen {
    display: none;
  }
}
</style>
