<template>
  <div class="why sec big-screen" id="why">
    <h3>We’ve revolutionized the entire<br />development process.</h3>
    <div class="content">
      <div class="reasons">
        <div class="reason">
          <div class="text">
            <div class="small">Prototypes</div>
            <h6>Envision your final project</h6>
            <p>
              We don’t just design; we craft detailed prototypes and visual
              representations to help you fully envision your project in real
              life.
            </p>
          </div>
          <img src="../assets/Mock.png" alt="" />
        </div>
        <div class="reason">
          <img src="../assets/Track.svg" alt="" />
          <div class="text">
            <div class="small">Tracking</div>
            <h6>Live development progress tracking</h6>
            <p>
              We keep you updated on the status of your projects development by
              providing a live development status update on our website
            </p>
          </div>
        </div>
        <div class="reason">
          <div class="text">
            <div class="small">Meetings</div>
            <h6>We do meetings modern style</h6>
            <p>
              Say goodbye to conventional meetings. Let's discuss the brief and
              go over the design over a cup of coffee at your nearest coffee
              shop, on us of course.
            </p>
          </div>
          <img src="../assets/Coff.png" alt="" />
        </div>
        <div class="reason">
          <img src="../assets/Pay.svg" alt="" />
          <div class="text">
            <div class="small">Actions</div>
            <h6>Make all crucial decisions on the app</h6>
            <p>
              All decisions and actions that would usually require a meeting can
              be simply done on the app
            </p>
            <ul>
              <li>Request a quotation</li>
              <li>Initiate a project</li>
              <li>View & approve designs</li>
              <li>Schedule meetings</li>
              <li>Make payments</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="why sec small-screen" id="why_">
    <h3>We’ve revolutionized the entire development process.</h3>
    <div class="content">
      <div class="reason">
        <div class="text">
          <div class="small">Prototypes</div>
          <h6>Envision your final project</h6>
          <p>
            We don’t just design; we craft detailed prototypes and visual
            representations to help you fully envision your project in real
            life.
          </p>
        </div>
        <img src="../assets/Mock.png" alt="" />
      </div>
      <div class="reason">
        <div class="text">
          <div class="small">Tracking</div>
          <h6>Live development progress tracking</h6>
          <p>
            We keep you updated on the status of your projects development by
            providing a live development status update on our website
          </p>
        </div>
        <img src="../assets/Track.svg" alt="" />
      </div>
      <div class="reason">
        <div class="text">
          <div class="small">Meetings</div>
          <h6>We do meetings modern style</h6>
          <p>
            Say goodbye to conventional meetings. Let's discuss the brief and go
            over the design over a cup of coffee at your nearest coffee shop, on
            us of course.
          </p>
        </div>
        <img src="../assets/Coff.png" alt="" />
      </div>
      <div class="reason">
          <div class="text">
            <div class="small">Actions</div>
            <h6>Make all crucial decisions on the app</h6>
            <p>
              All decisions and actions that would usually require a meeting can
              be simply done on the app
            </p>
            <ul>
              <li>Request a quotation</li>
              <li>Initiate a project</li>
              <li>View & approve designs</li>
              <li>Schedule meetings</li>
              <li>Make payments</li>
            </ul>
          </div>
          <img src="../assets/Pay.svg" alt="" />
        </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.reasons {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 100%;
}
.reasons .reason {
  display: flex;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.reasons .reason img {
  height: 18rem;
}
.text {
  width: 26rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.text .small {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--blue);
}
.text h6 {
  font-weight: 600;
  font-size: 1.6rem;
}
.text p {
  font-size: 0.9rem;
  font-weight: 400;
}
.reason ul li {
  font-size: 0.9rem;
  color: grey;
}
.reason ul {
  padding-left: 2rem;
}
.small-screen .reason {
  width: 100%;
}
.small-screen .reason .text {
  width: 100%;
}
.small-screen .reason img {
  width: 100%;
}
.small-screen .reason {
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
}
#app .small-screen .content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
</style>
